@charset "UTF-8";

@import "settings";
@import "mixins";

/*****************************************************************************
Responsive Block
*****************************************************************************/
#Wrapper {
  position: relative;
  left: 0;
}
.responsive-block-open #Wrapper {
  left: 0;
  @include transition(all 0.6s ease);
}
#responsive-block {
  visibility: hidden;
  position: fixed;
  left: -400px;
  top: 0;
  z-index: 9999;
  width: 400px;
  height: 100%;
  background: #1f1f1f;
  @include transition(all 0.6s ease);
  .lang {
    text-align:center;
    li {
      display:inline-block;
      a {
        display:inline-block;
        padding:13px 15px 12px;
        color:#eff2f7;
        line-height:20px;
        font-size:16px;
        font-weight:300;
        text-transform:uppercase;
        &:hover {
          color: $color-links;
        }
      }
      &.active a {
        font-weight:700;
      }
    }
  }
  nav li a {
    display:block;
    padding: 15px 30px;
    color: #fff;
    line-height: 20px;
    font-size: 18px;
    font-weight:300;
    @include transition(all 0.2s ease);
  }
  nav > ul > li > a:hover, nav > ul > li:hover > a, nav > ul > li.active > a {
    background-color: $color-links;
    color: #fff;
  }
  .submenu .level-links {
    visibility:hidden;
    position:absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    background-color: #2b2b2b;
    @include transition(all 0.6s ease);
    li {
      border-bottom: 1px solid  #1f1f1f;
      a {
        color: #a4a4a4;
        @include transition(all 0.2s ease);
        &:hover {
          color: #fff;
        }
      }
    }
    .back {
      padding: 15px 30px;
      background: $color-links url(../img/arrow-left-12x23.png) no-repeat 10px center;
      color: #fff;
      line-height: 20px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .bottom-social {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    background: $color-links;
    a {
      display:block;
      float:left;
      width:100%;
      height:50px;
      color: #fff;
      line-height:50px;
      font-size:17px;
      font-weight:500;
      text-align:center;
      @include transition(all 0.3s ease);
      &:hover {
        color: #fff;
      }
    }
  }
  .submenu.active .level-links {
    visibility:visible;
    left:0;
  }
}
.responsive-block-open #responsive-block {
  visibility: visible;
  left: 0;
  @include transition(all 0.6s ease);
}

/*****************************************************************************
Header
*****************************************************************************/
#Header {
  position: fixed;
  z-index: 1040;
  width: 100%;
  padding: 0 40px 0 73px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
  .header-content {
    display: flex;
    justify-content: space-between;
    .left-col {
      padding-right: 40px;
      padding-top: 18px;
      @include transition(all 0.4s ease);
      a.logo {
        display:block;
        width: 199px;
        height: 109px;
        background: url(../img/logo.png) no-repeat 0 0;
        background-size: 100% auto;
        text-indent: -9999px;
        @include transition(all 0.4s ease);
      }
    }
    .right-col {
      flex: 1;
      .top-bar {
        visibility: visible;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 70px;
        @include transition(all 0.4s ease);
        .phone {
          display: flex;
          p {
            margin: 0;
            &.tel {
              color: #080808;
              font-size: 17px;
              line-height: 17px;
              font-weight: 500;
              a {
                color: #080808;
              }
            }
            &.text {
              margin-right: 28px;
              color: #5b5b5b;
              font-size: 12px;
              line-height: 15px;
              font-weight: 500;
            }
          }
        }
        .email {
          margin-left: 53px;
          a {
            color: #080808;
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
            @include transition(all 0.3s ease);
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .like-this {
          margin-left: 42px;
          padding-left: 53px;
          background: url(../img/icons/slash.png) no-repeat center left;
          .fb-like {
            margin-top: 8px;
          }
        }
      }
      .menu-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 86px;
        border-top: 1px solid #e5e5e5;
        a.valuation {
          display: block;
          margin-left: 25px;
          padding: 0 76px;
          height: 50px;
          line-height: 46px;
          color: #d93036;
          border: 2px solid #e5e5e5;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          cursor: pointer;
          @include border-radius(25px);
          @include transition(all 0.3s ease);
          &:hover, &:focus {
            outline: none;
            background-color: #222;
            border-color: #222;
            color: #fff;
          }
        }
      }
    }
  }
}

#Header.sticky {
  .header-content {
    .left-col {
      padding-right: 0;
      padding-top: 0;
      display: flex;
      align-items: center;
      a.logo {
        width: 120px;
        height: 66px;
      }
    }
    .right-col {
      .top-bar {
        visibility: hidden;
        height: 0;
        .like-this {
          visibility: hidden;
          opacity: 0;
        }
      }
      .menu-bar {
        border-top: 0;
      }
    }
  }
}

#menu {
  & > ul {
    display: flex;
    & > li {
      position: relative;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      & > a {
        display: block;
        padding: 0 25px;
        line-height: 85px;
        color: #222;
        @include transition(all 0.3s ease);
        &:hover {
          color: $color-links;
        }
      }
      &.active > a {
        color: $color-links;
      }
      &:hover > a {
        color: $color-links;
      }
      .level-links {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 85px;
        right: -60px;
        z-index: 9;
        width: 300px;
        padding: 28px 35px;
        @include box-shadow(0 0 15px rgba(0, 0, 0, 0.3));
        background: #129358;
        background: -webkit-linear-gradient(to right, #1bb26d , #129358);
        background: -o-linear-gradient(to right, #1bb26d , #129358);
        background: -moz-linear-gradient(to right, #1bb26d , #129358);
        background: linear-gradient(to right, #1bb26d , #129358);
        @include transition(all 0.3s ease);
        .links li {
          text-transform: none;
          a {
            display: flex;
            padding: 8px 0;
            color: #fff;
            font-size: 15px;
            line-height: 17px;
            font-weight: 500;
            text-shadow: 1px 1px #107d4b;
            &:hover {
              color: #191714;
              text-shadow: none;
            }
            &:before {
              content: "";
              float: left;
              margin: 7px 15px 0 0;
              width: 4px;
              height: 4px;
              background-color: #fff;
              @include border-radius(4px);
              text-shadow: 1px 1px #b6040b;
            }
            &:hover:before {
              background-color: #191714;
            }
          }
        }
      }
      &:hover {
        .level-links {
          visibility: visible;
          opacity: 1;
        }
      }
      &.submenu {
        &:after {
          visibility: hidden;
          opacity: 0;
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #129358;
        }
        &:hover {
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#nav-icon span {
  background-color: #000;
}

/*****************************************************************************
Content
*****************************************************************************/
#Content {
  padding-top: 156px;
}

.text-line {
  display: flex;
  align-items: center;
  h3 {
    margin-right: 24px;
    color: #222;
    font-size: 38px;
    line-height: 38px;
    font-weight: 500;
  }
  .line {
    margin-top: 5px;
    flex: 1;
    height: 1px;
    background-color: #d7d6d6;
  }
}

#slider-main-content {
  position: relative;
  background-color: #f6f6f6;
  .slider-main {
    .slide {
      .content-wrapper {
        margin: 0 auto;
        max-width: 1545px;
        .desc {
          width: 488px;
          height: 600px;
          padding-top: 148px;
          h5 {
            margin-bottom: 36px;
            color: #222;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
          }
          h2 {
            margin-bottom: 43px;
            color: #000;
            font-family: 'Jost', sans-serif;
            font-weight: 600;
            font-size: 48px;
          }
        }
      }
      .photo {
        position: absolute;
        top: 0;
        right: 0;
        width: 1228px;
        height: 600px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 999;
          width: 154px;
          height: 600px;
          background: url(../img/slide-bg.png) no-repeat left bottom;
        }
        img {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
  .bx-controls {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    max-width: 1170px;
    margin: 0 auto;
    .bx-pager {
      display: flex;
      margin-left: 682px;
      .bx-pager-link {
        display: block;
        margin-right: 7px;
        width: 21px;
        height: 3px;
        background: #bebfc3;
        text-indent: -9999px;
        &.active {
          background: $color-links;
        }
      }
    }
  }
}

#offer-main {
  position: relative;
  z-index: 999;
  margin: -45px auto 0;
  width: 1694px;
  .items {
    display: flex;
    align-items: center;
    height: 194px;
    background-color: #fff;
    @include border-radius(10px);
    @include box-shadow(0 8px 43px rgba(23, 23, 23, 0.2));
    .item {
      position: relative;
      width: 20%;
      height: 100%;
      &:after {
        content: '';
        position: absolute;
        top: 26px;
        right: 0;
        display: block;
        width: 1px;
        height: 142px;
        background-color: #e0e0e0;
      }
      &:last-child:after {
        display: none;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 10px;
        color: $color-links;
        font-size: 30px;
        line-height: 32px;
        font-weight: 600;
        text-align: center;
        @include transition(all 0.3s ease);
        &:hover {
          color: #222;
        }
      }
    }
  }
}

#about-us {
  padding: 105px 0 62px;
  .inside {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left-col {
      flex: 1;
      .photo {
        img {
          display: block;
          max-width: 100%;
        }
      }
    }
    .right-col {
      width: 840px;
      margin-bottom: 8px;
      h2 {
        color: #222;
        font-size: 48px;
        line-height: 48px;
        font-weight: 600;
      }
      h3 {
        margin-bottom: 34px;
        color: #222;
        font-size: 48px;
        line-height: 48px;
        font-weight: 300;
      }
      p {
        margin-bottom: 37px;
        color: #222;
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 112px;
    .item {
      display: flex;
      align-items: center;
      height: 109px;
      color: #999;
      font-family: "Poppins", sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 19px;
      text-transform: uppercase;
      &.i1 {
        padding-left: 136px;
        background: url(../img/icon-house.png) no-repeat 0 center;
      }
      &.i2 {
        padding-left: 140px;
        background: url(../img/icon-material.png) no-repeat 0 center;
      }
      &.i3 {
        padding-left: 127px;
        background: url(../img/icon-face.png) no-repeat 0 center;
      }
    }
  }
}

#projects {
  background: url(../img/bg-grey.png) repeat-x left bottom;
  .inside {
    padding: 67px 0 90px;
    border-top: 1px solid #d7d6d6;
    h3 {
      margin-bottom: 10px;
      color: #222;
      font-weight: 500;
      font-size: 38px;
      text-align: center;
    }
    h5 {
      margin-bottom: 69px;
      color: #666;
      font-size: 19px;
      line-height: 27px;
      font-weight: 300;
      text-align: center;
    }
    .projects-slider-content {
      position: relative;
      display: flex;
      justify-content: center;
    }
    .slide {
      a {
        position: relative;
        display: block;
        .photo {
          height: 431px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
            @include transition(all 0.4s ease);
          }
          .bg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 315px;
            background: url(../img/photo-bg.png) repeat-x left bottom;
          }
        }
        p {
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
          margin: 0;
          color: #fff;
          font-size: 17px;
          line-height: 17px;
          font-weight: 300;
          text-align: center;
          @include transition(all 0.4s ease);
        }
        &:hover {
          .photo {
            img {
              @include transform(scale(1.1));
              @include transition(all 0.4s ease);
            }
          }
          p {
            bottom: 60px;
          }
        }
      }
    }
    .bx-controls-direction {
      position: absolute;
      bottom: -70px;
      left: 0;
      display: flex;
      a {
        display: block;
        width: 18px;
        height: 14px;
        text-indent: -9999px;
        @include transition(all 0.4s ease);
        &.bx-prev {
          margin-right: 10px;
          background: url(../img/icons/prev-grey2.png) no-repeat 0 0;
        }
        &.bx-next {
          background: url(../img/icons/next-grey2.png) no-repeat 0 0;
        }
        &:hover {
          &.bx-prev {
            background: url(../img/icons/prev-black2.png) no-repeat 0 0;
          }
          &.bx-next {
            background: url(../img/icons/next-black2.png) no-repeat 0 0;
          }
        }
      }
    }
    .line {
      position: relative;
      margin-top: 62px;
      margin-left: 58px;
      height: 1px;
      background-color: #d7d6d6;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 42px;
        height: 1px;
        background-color: $color-links;
      }
    }
  }
}

#why-us {
  margin-bottom: 100px;
  padding: 120px 0 0;
  background: url(../img/house.png) no-repeat left bottom;
  h2.mobile {
    display: none;
    margin-bottom: 40px;
    padding-bottom: 20px;
    color: #222;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    border-bottom: 1px solid #d7d6d6;
  }
  .inside {
    display: flex;
    justify-content: space-between;
    .left-col {
      flex: 1;
      margin-right: 115px;
    }
    .right-col {
      width: 702px;
      h2 {
        margin-bottom: 51px;
        padding-bottom: 31px;
        color: #222;
        font-weight: 500;
        font-size: 38px;
        line-height: 30px;
        border-bottom: 1px solid #d7d6d6;
      }
    }
    .name-tabs {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 105px;
      h3.tab {
        color: #afafb0;
        font-size: 15px;
        line-height: 36px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        &:hover, &.is-visible {
          color: $color-links;
        }
      }
    }
    .content-tabs {
      .item {
        display: none;
        &.is-visible {
          display: block;
        }
        h3 {
          margin-bottom: 14px;
          color: #222;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
          &:after {
            content: '';
            display: block;
            margin-top: 13px;
            width: 100px;
            height: 2px;
            background-color: $color-links;
          }
        }
        p {
          margin: 0;
          color: #616161;
          font-size: 17px;
          line-height: 30px;
          font-weight: 300;
        }
      }
    }
  }
}

#partners {
  .inside {
    padding: 43px 0 61px;
    border-bottom: 1px solid #d7d6d6;
  }
  .slider-partners-content {
    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
}

#opinions-news {
  padding: 71px 0 82px;
  .inside {
    display: flex;
    justify-content: space-between;
    h3 {
      color: #000;
      font-size: 32px;
      font-weight: 400;
    }
    p.info {
      margin: 14px 0 50px;
      color: #000;
      font-size: 17px;
      font-weight: 300;
    }
    a.more {
      color: #999;
      font-size: 17px;
      font-weight: 500;
      line-height: 19px;
      @include transition(all 0.3s ease);
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #999;
        @include transition(all 0.3s ease);
      }
      &:hover {
        color: $color-links;
        &:after {
          background: $color-links;
        }
      }
    }
    .left-col {
      width: 550px;
      .opinions {
        position: relative;
        min-height: 226px;
        border-bottom: 1px solid #d7d6d6;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 42px;
          height: 1px;
          background-color: $color-links;
        }
        .slide {
          .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            h6 {
              color: #222;
              font-size: 17px;
              font-weight: 500;
            }
            time {
              color: #999;
              font-size: 13px;
              font-weight: 300;
            }
          }
          .star {
            margin-bottom: 25px;
          }
          p {
            margin: 0;
            color: #444;
            font-size: 17px;
            line-height: 26px;
            font-weight: 300;
          }
        }
      }
    }
    .right-col {
      width: 773px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .items {
        display: flex;
        justify-content: space-between;
        .item {
          width: 369px;
          a {
            .photo {
              margin-bottom: 32px;
              width: 369px;
              height: 226px;
              overflow: hidden;
              img {
                display: block;
                width: 100%;
                height: 100%;
                @include transition(all 0.4s ease);
              }
            }
            h5 {
              margin-bottom: 16px;
              font-size: 19px;
              line-height: 27px;
              font-weight: 500;
              color: #333;
              @include transition(all 0.3s ease);
            }
            .date {
              color: #ababad;
              font-size: 13px;
              font-weight: 500;
            }
            &:hover {
              .photo {
                img {
                  -webkit-filter: brightness(0.7);
                  -moz-filter: brightness(0.7);
                  -o-filter: brightness(0.7);
                  -ms-filter: brightness(0.7);
                  filter: brightness(0.7);
                  @include transform(scale(1.1));
                  @include transition(all 0.4s ease);
                }
              }
              h5 {
                color: $color-links;
              }
            }
          }
        }
      }
    }
  }
}

#free {
  margin-bottom: 96px;
  background: url(../img/become-partner.jpg) no-repeat center 0;
  .inside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 177px;
    padding: 0 137px 0 117px;
    h3 {
      margin-bottom: 15px;
      color: #fff;
      font-weight: 500;
      font-size: 48px;
      line-height: 32px;
      filter: drop-shadow(3px 3px 5px rgba(24, 24, 25, 0.15));
    }
    h6 {
      color: #fff;
      opacity: 0.5;
      font-weight: 500;
      font-size: 15px;
      text-transform: uppercase;
    }
    h5 {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      filter: drop-shadow(3px 3px 5px rgba(24, 24, 25, 0.15));
      a {
        display: inline-block;
        padding-right: 36px;
        color: #fff;
        background: url(../img/icons/next-white.png) no-repeat right center;
        transition: all 0.3s ease;
        &:hover {
          text-decoration: underline;
          opacity: 0.8;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

#contact-home {
  padding-bottom: 98px;
  background: url(../img/car-bg.png) no-repeat right bottom;
  .info {
    padding-left: 773px;
    min-height: 414px;
    background: url(../img/contact-home.png) no-repeat 55px 0;
    h3 {
      margin-bottom: 23px;
      padding-top: 30px;
      color: #222;
      font-size: 30px;
      font-weight: 400;
      text-transform: uppercase;
    }
    h6 {
      margin-bottom: 5px;
      color: #222;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
    }
    h5 {
      margin-bottom: 23px;
      color: #222;
      font-size: 19px;
      line-height: 23px;
      font-weight: 400;
    }
    h4 {
      color: #222;
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      letter-spacing: -0.25px;
      &.tel {
        margin-top: 51px;
      }
      a {
        color: #222;
        &.email {
          position: relative;
          color: $color-links;
          transition: all 0.3s ease;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: $color-links;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #000;
            &:after {
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

/*****************************************************************************
Footer
*****************************************************************************/
#Footer {
  padding: 49px 0 18px;
  background-color: #222;
  .social-links {
    color: #c0c0c0;
    .inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      .social-icons {
        display: flex;
        align-items: center;
        p {
          margin: 0 13px 0 0;
          color: #a9aaab;
          font-weight: 400;
          text-transform: uppercase;
        }
        ul {
          display: flex;
          padding: 0;
          line-height: 25px;
          li {
            padding: 0 10px;
            font-family: "Poppins", sans-serif;
            a {
              font-size: 21px;
              color: #a4a4a4;
              font-weight: 700;
              @include transition(all 0.3s ease);
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
      .links{
        a {
          font-size: 17px;
          font-weight: 300;
          color: #a9aaab;
          @include transition(all 0.3s ease);
          &:hover {
            color: #fff;
          }
        }
        .space {
          display: inline-block;
          padding: 0 10px;
          color: #a9aaab;
        }
      }
    }
  }
  .menu {
    .inside {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 115px;
      border-top: 1px solid #696969;
      border-bottom: 1px solid #696969;
      .menu-list {
        display: flex;
        li {
          padding: 0 34px;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
          a {
            color: #d4d6d9;
            @include transition(all 0.3s ease);
            &:hover {
              color: $color-links;
            }
          }
        }
      }
    }
  }
  .signature {
    p {
      margin: 0;
      color: #c0c0c0;
      font-size: 17px;
      font-weight: 300;
      br {
        display: none;
      }
    }
    .inside {
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color:#fff;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/*****************************************************************************
Subpages
*****************************************************************************/
.subpage-simple {
  margin: 50px 0 90px;
}

.subpage-contact {
  padding: 0 0 97px;
  .contact-info {
    padding-top: 81px;
    .inside {
      display: flex;
      justify-content: space-between;
    }
    .left-col {
      padding-left: 122px;
      padding-right: 50px;
      width: 566px;
      background: url(../img/plane.jpg) no-repeat right bottom;
      border-right: 1px solid #d0d1d3;
      .item {
        display: none;
        margin-bottom: 69px;
        padding-left: 31px;
        border-left: 2px solid $color-links;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          margin-bottom: 23px;
          color: #222;
          font-size: 26px;
          font-weight: 400;
          text-transform: uppercase;
        }
        h5 {
          color: #222;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          text-transform: uppercase;
        }
        p {
          margin: 18px 0 0;
          color: #222;
          font-size: 19px;
          line-height: 23px;
          font-weight: 400;
          a {
            color: $color-links;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        h4 {
          color: #222;
          font-size: 22px;
          line-height: 34px;
          font-weight: 400;
          letter-spacing: -0.25px;
          &.tel {
            margin-top: 40px;
          }
          a {
            color: #222;
            &.email {
              position: relative;
              color: $color-links;
              transition: all 0.3s ease;
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-links;
                transition: all 0.3s ease;
              }
              &:hover {
                color: #000;
                &:after {
                  background-color: #000;
                }
              }
            }
          }
        }
      }
    }
    .right-col {
      width: 720px;
      h3 {
        margin-bottom: 46px;
        color: #222;
        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
      }
      .form-simple textarea.form-control {
        height: 132px;
      }
      .btn-red {
        width: 100%;
      }
    }
  }
}

#contact-page-map {
  position: relative;
  height: 568px;
  background: #eaeaea;
  .city {
    position: absolute;
    right: 62%;
    top: 0;
    width: 736px;
    height: 100%;
    background: url(../img/city.jpg) no-repeat;
  }
  .address-box {
    position: absolute;
    right: 50%;
    top: 84px;
    z-index: 990;
    margin-right: -302px;
    padding: 93px 100px 0;
    width: 605px;
    height: 401px;
    background-color: #fff;
    @include box-shadow(0px 0px 13px 0px rgba(2, 2, 2, 0.4));
    h4 {
      color: #232427;
      font-size: 24px;
      line-height: 26px;
      font-weight: 600;
    }
    h5 {
      margin-bottom: 25px;
      color: #727272;
      font-size: 17px;
      font-weight: 300;
    }
    p {
      margin-bottom: 25px;
      color: #232427;
      font-size: 19px;
      line-height: 23px;
      font-weight: 400;
      a {
        position: relative;
        color: $color-links;
        @include transition(all 0.3s ease);
        &:after {
          content: '';
          position: absolute;
          bottom: 2px;
          right: 0;
          display: block;
          width: 100%;
          height: 2px;
          background-color: $color-links;
          @include transition(all 0.3s ease);
        }
        &:hover {
          color: #000;
          &:after {
            background-color: #000;
          }
        }
      }
    }
  }
  #map {
    position: absolute;
    right: 0;
    top: 0;
    width: 62%;
    height: 568px;
  }
}

.subpage-blog {
  padding: 100px 0 50px;
  .inside {
    display: flex;
    flex-wrap: wrap;
    padding: 0 116px;
    .item {
      width: 369px;
      margin-bottom: 50px;
      margin-right: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      a {
        .photo {
          margin-bottom: 32px;
          width: 100%;
          height: 226px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
            @include transition(all 0.4s ease);
          }
        }
        h5 {
          margin-bottom: 16px;
          font-size: 19px;
          line-height: 27px;
          font-weight: 500;
          color: #333;
          @include transition(all 0.3s ease);
        }
        .date {
          color: #ababad;
          font-size: 13px;
          font-weight: 500;
        }
        &:hover {
          .photo {
            img {
              -webkit-filter: brightness(0.7);
              -moz-filter: brightness(0.7);
              -o-filter: brightness(0.7);
              -ms-filter: brightness(0.7);
              filter: brightness(0.7);
              @include transform(scale(1.1));
              @include transition(all 0.4s ease);
            }
          }
          h5 {
            color: $color-links;
          }
        }
      }
    }
  }
}

.subpage-blog-details {
  margin: 70px 0 90px;
  .inside {
    display: flex;
    justify-content: space-between;
    .blog-details {
      width: 974px;
      .blog-details__header {
        margin-bottom: 50px;
        h3 {
          position: relative;
          margin-bottom: 5px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #333;
          font-size: 36px;
          line-height: 36px;
          font-weight: 500;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 189px;
            height: 3px;
            background-color: $color-links;
          }
        }
        .date {
          color: #969ca1;
          font-size: 14px;
          font-weight: 300;
        }
      }
      img {
        display: block;
        max-width: 100%;
        margin: 40px 0;
      }
    }
    .blog-sidebar {
      width: 374px;
      margin-top: 15px;
      aside {
        margin-bottom: 30px;
        h3 {
          margin-bottom: 43px;
          padding-bottom: 24px;
          border-bottom: 1px solid #c9cacf;
          color: #222;
          font-size: 17px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      .widget-latest-news {
        .items {
          .item a {
            display: flex;
            justify-content: space-between;
            margin-bottom: 19px;
            padding-bottom: 16px;
            border-bottom: 1px solid #f3f3f3;
            .photo {
              width: 85px;
              img {
                display: block;
                max-width: 100%;
                @include transition(all 0.3s ease);
              }
            }
            .desc {
              width: 272px;
              p {
                &.date {
                  margin-bottom: 6px;
                  color: #ababad;
                  font-size: 13px;
                  font-weight: 500;
                  text-transform: uppercase;
                }
                &.title {
                  margin: 0;
                  color: #414449;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 17px;
                }
              }
            }
            &:hover {
              .photo {
                img {
                  opacity: 0.9;
                }
              }
              .desc {
                p.title {
                  text-decoration: underline;
                }
              }
            }
          }
          .item:last-child a {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

.subpage-offer {
  padding: 0 0 60px;
  .inside {
    .name-tabs {
      display: flex;
      justify-content: center;
      margin: 50px 0 60px;
      border-bottom: 1px solid #e7e7e8;
      h3.tab {
        position: relative;
        margin: 0 28px;
        padding-bottom: 28px;
        color: #b8b7b7;
        font-size: 21px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        @include transition(all 0.4s ease);
        &:hover {
          color: #222;
        }
        &.is-visible {
          color: #222;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            background-color: $color-links;
            width: 100%;
            height: 3px;
          }
        }
      }
    }
    .content-tabs {
      .item {
        display: none;
        &.is-visible {
          display: block;
        }
        h3.name {
          display: none;
          margin-bottom: 25px;
          padding-bottom: 15px;
          border-bottom: 1px solid #e7e7e8;
          color: #222;
          font-size: 21px;
          font-weight: 600;
          text-transform: uppercase;
        }
        .boxes {
          display: flex;
          flex-wrap: wrap;
          .box {
            margin-bottom: 40px;
            margin-right: 32px;
            width: 325px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            a {
              display: block;
              @include transition(all 0.3s ease);
              .photo {
                position: relative;
                width: 100%;
                height: 231px;
                overflow: hidden;
                img {
                  display: block;
                  max-width: 100%;
                  @include border-radius(2px 2px 0 0);
                }
                p {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  right: 0;
                  margin: -18px auto 0;
                  width: 180px;
                  visibility: hidden;
                  opacity: 0;
                  @include transition(all 0.3s ease);
                }
              }
              .desc {
                padding: 29px 20px;
                background-color: #f6f6f6;
                border-width: 0 2px 2px 2px;
                border-style: solid;
                border-color: #f6f6f6;
                @include border-radius(0 0 2px 2px);
                @include transition(all 0.3s ease);
                h4 {
                  color: #000;
                  font-size: 22px;
                  font-weight: 600;
                  text-align: center;
                  @include transition(all 0.3s ease);
                }
                h5 {
                  color: #999;
                  font-size: 17px;
                  font-weight: 500;
                  text-align: center;
                }
              }
              &:hover {
                @include box-shadow(0 0 15px rgba(0, 0, 0, 0.3));
                .photo {
                  p {
                    visibility: visible;
                    opacity: 1;
                  }
                }
                .desc {
                  border-width: 0 2px 2px 2px;
                  border-style: solid;
                  border-color: $color-links;
                  h4 {
                    color: $color-links;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.subpage-projects {
  padding: 100px 0 50px;
  .inside {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 439px;
      margin-bottom: 50px;
      margin-right: 40px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      a {
        display: block;
        .photo {
          position: relative;
          width: 100%;
          height: 330px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            @include transition(all 0.3s ease);
          }
          .bg {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #0f7647;
            mix-blend-mode: multiply;
            @include transition(all 0.3s ease);
          }
          p {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: -8px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            @include transition(all 0.3s ease);
          }
        }
        &:hover {
          .photo {
            .bg, p {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.subpage-with-photo {
  margin: 123px 0 120px;
  .inside {
    display: flex;
    justify-content: space-between;
    .left-col {
      width: 600px;
      .photo {
        position: relative;
        img {
          position: absolute;
          z-index: 999;
          top: -27px;
          left: 66px;
          display: block;
          max-width: 100%;
          @include box-shadow(0 15px 30px rgba(23, 23, 24, 0.3));
        }
        &:before {
          content: '';
          display: block;
          width: 562px;
          height: 465px;
          background-color: #0e7546;
        }
      }
    }
    .right-col {
      width: 685px;
    }
  }
  &.different-typography {
    article {
      h5 a {
        display: inline-block;
        padding: 15px 0;
        text-decoration: underline;
      }
      p {
        line-height: 27px;
      }
    }
  }
}

.subpage-offer-details {
  margin: 65px 0 120px;
  .inside {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .photo-big {
      width: 606px;
      height: 405px;
      overflow: hidden;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .right-col {
      width: 692px;
      h3 {
        margin-bottom: 46px;
        padding-bottom: 34px;
        border-bottom: 1px solid #e7e7e8;
        color: $color-links;
        font-size: 34px;
        line-height: 32px;
        font-weight: 600;
      }
      .manufacturer {
        display: flex;
        align-items: center;
        margin-bottom: 47px;
        padding-bottom: 50px;
        border-bottom: 1px solid #e7e7e8;
        h5 {
          margin-right: 118px;
          color: #9d9c9e;
          font-size: 17px;
          line-height: 32px;
          font-weight: 600;
        }
        .logo {
          img {
            display: block;
            max-width: 100%;
          }
        }
      }
    }
  }
  .description {
    margin-top: 64px;
    h4 {
      margin-bottom: 34px;
      padding-bottom: 9px;
      border-bottom: 1px solid #e7e7e8;
      color: #666;
      font-size: 21px;
      line-height: 32px;
      font-weight: 600;
    }
    p {
      color: #222;
      font-size: 17px;
      line-height: 32px;
      font-weight: 400;
    }
  }
}
